import { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
// import { amazonTextImageAds } from './amazon-ads';
import './fonts.css';
import twitter from './images/twitter.svg';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-5ECVNC95JE');
ReactGA.send('pageview');

function App() {
    const golfer = '🏌️‍♂️';
    const golfBall = '⏝◦ ';
    let flag = '⛳️';
    const flagDeath = '🏴‍☠️'
    const link = '\nhttps://wordle.golf'
    // const par = '4';

    const textareaRef = useRef(null);

    const [golfle, setGolfle] = useState('');
    const [copied, setCopied] = useState(false);
    const [error, setError] = useState('');
    const [linkBack, setLinkBack] = useState(true)

    useEffect(() => {
        handleChange();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkBack]);

    function handleCreditChange() {
        setLinkBack(!linkBack);
    }

    function handleChange() {
        setCopied(false);

        const wordle = textareaRef.current.value;
        const wordleSplit = wordle.split('\n');
        const wordleLineLength = wordleSplit.length;
        wordleSplit.splice(0, 2);
        const wordleShort = wordleSplit.join('\n');
        let wordleNumber = '';
        const hardMode = wordle.includes('*');
        let golfScore = '';
        let shots = 0;

        if (wordle.includes('1/6')) {
            golfScore = 'Hole in one 💪';
            shots = 1;
        } else if (wordle.includes('2/6')) {
            golfScore = 'Eagle 🦅';
            shots = 2;
        } else if (wordle.includes('3/6')) {
            golfScore = 'Birdie 🐥';
            shots = 3;
        } else if (wordle.includes('4/6')) {
            golfScore = 'Par 💁‍♂️';
            shots = 4;
        } else if (wordle.includes('5/6')) {
            golfScore = 'Bogey 🥵';
            shots = 5;
        } else if (wordle.includes('6/6')) {
            golfScore = 'Double Bogey 🤬';
            shots = 6;
        } else if (wordle.includes('X/6')) {
            golfScore = 'Mulligan! 😱';
            shots = 7;
            flag = flagDeath
        } else {
            golfScore = '';
        }

        const shotsScore = golfBall.repeat(shots);

        const golfedWordle = wordleShort
            .replace(/⬜/g, '⚪️')
            .replace(/⬛/g, '⚪️')
            .replace(/🟨/g, '🟡')
            .replace(/🟩/g, '🟢');

        if (wordleLineLength >= 3 && wordle.includes('Wordle ')) {
            wordleNumber = wordle.match(/Wordle (\d{1,3},?\d{0,3})/)[1];

            setGolfle(
                `${hardMode ? '🏆 ' : ''}Golfle ${wordleNumber}: ${golfScore}

${golfer} ${shotsScore} ${flag}

${golfedWordle}
${linkBack ? link : ''}`
            );

            setError(``);
        } else if (wordle.length === 0) {
            setError(``);
        } else {
            setGolfle('');
            setError(
                `Looks like you're not copying your Wordle score properly/completely.`
            );
            setCopied(false);
        }
    }

    function copy() {
        setCopied(!error && golfle);
    }

    return (
        <Container>
            <Banner>
                <h1>🏌️‍♀️ Golfle 🏌️‍♂️</h1>
                <h2>Wordle score to golf score</h2>
                <p>Par is always 4</p>
            </Banner>

            <WordleGolfle>
                <Wordle>
                    <p><span>1.</span> Copy your Wordle score into here:</p>
                    <textarea
                        ref={textareaRef}
                        name="text-wordle"
                        onChange={handleChange}
                        rows="9"
                    />
                </Wordle>

                <Golfle>
                    <p><span>2.</span> Your new Golfle score will be converted here:</p>
                    <textarea
                        name="text-golfle"
                        value={golfle}
                        readOnly
                        rows="14"
                    />
                </Golfle>
            </WordleGolfle>

            {copied &&
                <CopyState>Copied results to clipboard</CopyState>
            }

            <Credit>
                <input
                    id="credit-check"
                    name="credit-check"
                    type="checkbox"
                    checked={linkBack}
                    onChange={handleCreditChange}
                />

                <label htmlFor="credit-check">Include link back to this page.</label>
            </Credit>

            <CopyToClipboard
                text={golfle}
                onCopy={copy}
            >
                <button disabled={error}>3. SHARE</button>
            </CopyToClipboard>

            <Error>
                {error}
            </Error>

            <Copy>
                <h2>Do people still play Wordle today?</h2>
                <p>Yes, a ridiculous amount of people still play daily. Visiting the NYT's page everyday to find out today's Wordle is now a lot of people's daily ritual. Have you figured out today's Wordle of the day?</p>
                <h2>What is today's Wordle?</h2>
                <p>That's cheating! Asking <em>what is the Wordle word today</em> will not get you an answer here.</p>
            </Copy>

            {/* TODO - Bring back ads */}
            {/* <AmazonAds>
                {amazonTextImageAds.map((ad, key) => (
                    <iframe
                        key={key}
                        title="amazon-ad"
                        sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                        style={{ width: '120px', height: '240px' }}
                        marginWidth="0"
                        marginHeight="0"
                        scrolling="no"
                        frameBorder="0"
                        src={ad.src}
                    ></iframe>
                ))}
            </AmazonAds>

            <AdsDisclosure>
                <p>Transparency stuff:</p>
                <ul>
                    <li>The above links pay me a commission if you click click-through and buy something.</li>
                    <li>As an Amazon Associate I earn from qualifying purchases.</li>
                </ul>
            </AdsDisclosure> */}

            <Adam>
                a fun little thing by
                {` `}
                <a
                    href="https://twitter.com/adamsawicki"
                    target="_blank"
                    rel="noreferrer"
                >
                    @AdamSawicki <img src={twitter} alt="Follow Adam on Twitter" />
                </a>
            </Adam>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 30px auto;
    font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
    text-align: center;

    button {
        padding: 10px 40px;
        background: #6aa964;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-size: 1.3em;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }
`

const Banner = styled.div`
    h1 {
        margin: 0 0 0.3em;
    }

    h2 {
        margin: 0 0 0.3em;
    }

    p {
        margin: 0;
        font-size: 1.1em;
    }
`

const WordleGolfle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto 15px;
    padding: 0 30px;
    font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;

    @media (min-width: 600px) {
        flex-direction: row;
        gap: 30px;
    }

    p {
        margin: 0 0 5px;
        font-size: 14px;
        font-weight: 500;
        color: #333;

        span {
        font-size: 1.5em;
        }
    }

    textarea {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-family: 'Clear Sans';
        font-size: 18px;
    }
`

const wordleGofleStyles = `
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 600px) {
        width: 50%;
    }
`

const Wordle = styled.div`
    ${wordleGofleStyles}

    textarea {
        margin: 0 0 20px;
        background: #f5f5f5;

        @media (min-width: 600px) {
            margin: 0;
        }
    }
`

const Golfle = styled.div`
    ${wordleGofleStyles}

    textarea {
        background: #e0ecdf;
    }
`

const CopyState = styled.div`
    width: 200px;
    margin: 0 auto;
    padding: 10px 40px;
    background: #000;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    color: #fff;
`

const Credit = styled.div`
    padding: 10px;
    font-size: 0.9em;
`

const Error = styled.div`
    padding: 15px;
    color: #c9b458;
    font-weight: 700;
`

const Copy = styled.div`
`
// TODO - Bring back ads
// const AmazonAds = styled.div`
//     display: flex;
//     justify-content: center;
//     gap: 20px;
//     flex-wrap: wrap;
//     margin-top: 45px;
// `

// TODO - Bring back ads
// const AdsDisclosure = styled.div`
//     width: 100%;
//     box-sizing: border-box;
//     margin: 15px auto 0;
//     padding: 0 10px;
//     font-size: 12px;
//     line-height: 1.2;
//     text-align: left;
//     color: #777;

//     p {
//         margin: 0 0 8px;
//     }

//     ul {
//         margin: 0;
//         padding: 0 0 0 20px;
//     }
// `

const Adam = styled.div`
    margin: 60px 0 0;
    font-size: 0.8em;

    @media (min-width: 600px) {
        position: fixed;
        bottom: 5px;
        right: 10px;
        margin: 0;
    }
    
    a {
        color: #1d9bf0;
        text-decoration: none;

        img {
            width: 14px;
        }
    }
`

export default App;
